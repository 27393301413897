*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.gradient_bg{
  background: rgb(6, 14, 47);
  background: linear-gradient(100deg, rgb(7, 14, 42) 0%, rgb(1, 2, 14) 10%);
}
a{
  font-family: var(--font-family);
  text-decoration: none;
  color: #ffffff;
}
.section_padding{
  padding: 4rem 6rem;
}
/*.section__margin{
  margin: 0 6rem ;
}*/

@media screen and (max-width: 700px) {
  .section_padding {
    padding: 2rem 2rem;
  }

  /*.section__margin {
    margin: 4rem;
  }*/
}

/*@media screen and (max-width: 550px) {
  .section_padding {
    padding: 2rem 4rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  } 
}*/