.experience_flex-container{
    
    display: flex;
    flex-direction: column;
}
.experience_title{
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.experience_title h1{
    font-family: var(--font-family);
    font-size: 50px;
    font-weight: 500;
    line-height: 40px;
    color: #ffffff;
}
.experience_title p{
    font-family: var(--font-family);
    font-size: 15px;
    font-weight: 300;
    color: #ffffff;
}
.experience_content{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /*justify-content: space-between;*/
    column-gap: 10px;
}
.experience_card{
    width: calc(50% - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #2f337c;
    border: linear-gradient(135deg, #2B3099, #198ACC);
    margin-top: 4rem;
    border-radius: 20px;
}
.card_title{
    padding: 1rem 0;
}

.card_title h1{
    font-family: var(--font-family);
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    color: #ffffff;
}
.card_info{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
@media screen and (max-width:1050px){
    .experience_content{
        flex-direction: column;
    }
    .experience_card{
        width: 100%;
    }
}
@media screen and (max-width:650px){
    .card_info{
        flex-direction: column;
        justify-content: center;
    }
    
}
@media screen and (max-width:500px){
    .card_title{
        text-align: center;
        padding: 1rem 1rem;
    }
    .card_title h1{
        font-size: 25px;
       
    }
}
