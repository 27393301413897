.navbar_flex-container{
    
    padding: 2rem 6rem;
    display: flex;
    justify-content: space-between;
}
.links_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.logo{
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
}
.links_container p{
    margin-left: 4rem;
    font-size: 18px;
    font-weight: 300;
}
.links_container a:hover{
    border-bottom: 4px solid green;
}

.menu_mobile{
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 1rem;
    display: none;
}
.menu_mobile-container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    padding: 2rem;
    
    position: absolute;
    top: 60px;
    right: 2rem;
    border-radius: 25px;
    background: #060b3c;
}
.menu_mobile-container p{
    font-family: var(--font-family);
    font-size: 18px ;
    line-height: 44px;
    
}
.active{
    border-bottom: 4px solid #FF4820;
}
@media screen and (max-width: 850px) {
    .links_container {
        display: none;
    }

    .menu_mobile {
        display: flex;
    }
    .navbar_flex-container{
    
        padding: 1rem 2rem;
    }
    .logo{
        font-size: 12px;
    }
}
@media screen and (max-width:700px){
    .navbar_flex-container{
    
        padding: 1rem 2rem;
    }
}