.about_flex-container{
    display: flex;
    flex-direction: column;
    color: white;
    margin-top:2rem;
}
.about_title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.about_title p{
    font-family: var(--font-family);
    font-size: 15px;
    font-weight: 300;
}
.about_title h1{
    font-family: var(--font-family);
    font-size: 40px;
    font-weight: 500;
    line-height: 40px;
}
.about_content{
    display: flex;
    justify-content: center;
    margin-top: 4rem;
}
.about_image{
    flex: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
}
.about_image img{
    width: 350px;
    height: 350px;
    /*clip-path: circle();*/
    border-radius: 50%;
    border: 10px solid #a5abcd;
}
.about_info{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
    
}
.about_cards{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 10px;
    
}
.about_experience, .about_education{
    border: 2px solid #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    border-radius: 20px;
    text-align: center;
}
.about_experience h3, .about_education h3{
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 40px;
    font-weight: 300;
}
.about_experience p, .about_education p{
    font-family: var(--font-family);
    font-size: 15px;
    line-height: 40px;
    font-weight: 300;
    color: #848fcd;
}
.about_story{
    margin-top: 2rem;
}
.about_story p{
    font-family: var(--font-family);
    font-size: 18px;
    color: #848fcd;
}
@media screen and (max-width:1050px){
    .about_story p{
        font-size: 15px;
    }
    .about_content{
        flex-direction: column;
        align-items: center;
    }
    .about_image img{
        max-width: none;
    }
    .about_cards{
        margin-top: 2rem;
    }
}
@media screen and (max-width:650px){
    .about_story p{
        font-size: 12px;
    }
    .about_experience h3, .about_education h3{
        font-size: 15px;
        line-height: 30px;
    }
    .about_experience p, .about_education p{
        font-size: 12px;
        line-height: 30px;
    }
    .about_image img{
        width: 300px;
        height: 300px;
       
    }
}
@media screen and (max-width:450px){
    .about_cards{
        grid-template-columns: repeat(1,1fr);
        row-gap: 10px;
    }
}
