.header_flex-container{
    display: flex;
}
.header_content{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 4rem;
}

.header_title h1{
    font-family: var(--font-family);
    font-size: 62px;
    font-weight: 800;
    color: #ffffff;
    line-height: 72px;
}
.header_title p{
    font-family: var(--font-family);
    margin-top: 1rem;
    font-size: 20px;
    color: #848fcd;
}
.btn_contact{
    margin-top: 2rem;
}
.btn_contact button{
    font-family: var(--font-family);
    font-size: 18px;
    padding: 0 2rem ;
    min-height: 50px;
    border-radius: 6px;
    background: #FF4820;
    border: 2px solid #FF4820;
    color: #fff;
    cursor: pointer;
    outline: none;
    transition: transform 0.3s;
}
.btn_contact button:hover{
    transform: translateY(-10px);
    background: #2E8B57;
    border: 2px solid #2E8B57;
}
.header_image{
    flex: 0.4;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: float 6s ease-in-out infinite;
    
}
.header_image img{
    width: 100%;
    height: 100%;
}
.professional_links{
    margin-top: 2rem;
    display: flex;
    column-gap: 10px;
}
.professional_links a{
    font-size: 30px;
}

@keyframes float {
    0%{
        box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.6);
        transform: translatey(0px);
    }
    50%{
        box-shadow: 0px 25px 15px 0px rgba(0,0,0,0.2);
        transform: translatey(-30px);
    }
    100%{
        box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.6);
        transform: translatey(0px);
    }
    
}
@media screen and (max-width:1050px){
    .header_flex-container{
        flex-direction: column;
    }
    .header_content{
        margin-top: 0;
    }
    .header_title h1{
        font-size: 50px;
        line-height: 60px;
    }
    .header_image img{
        width: 70%;
    }
}
@media screen and (max-width:650px){
    .header_title h1{
        font-size: 48px;
        line-height: 50px;
    }
   
    .header_title p{
        font-size: 15px;
    }
}
@media screen and (max-width:590px){
    .header_title h1{
        font-size: 34px;
        line-height: 42px;
        word-spacing: -0.04em;
        letter-spacing: -0.04em;
    }
}