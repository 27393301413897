.project_container{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}
.project_container_content{
    text-align: center;
}
.project_container_content h2{
    font-family: var(--font-family);
    font-size: 20px;
}
.project_actions{
    display: flex;
    justify-content: space-around;
    
    align-items: center;
}
.project_actions button{
    font-family: var(--font-family);
    font-weight: 400;
    height: 50px;
    width: 150px;
    font-size: 15px;
   /* padding: 1rem 2rem;*/
    border-radius: 10px;
}
.project_actions button:hover{
    color: #ffffff;
    background: #2E8B57	;
    cursor: pointer;
}
@media screen and (max-width:450px){
    .project_actions button{
        
        width: 100px;
        font-size: 12px;
       
    }
}