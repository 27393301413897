.contact_flex-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact_title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.contact_title h1{
    font-family: var(--font-family);
    font-size: 50px;
    font-weight: 500;
    line-height: 60px;
    color: #ffffff;
}
.contact_title p{
    font-family: var(--font-family);
    font-size: 15px;
    font-weight: 300;
    color: #ffffff;
}
.contact_info{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    max-width: 600px;
    border-radius: 25px;
    background: #2f337c;
}
.contact_info_icon{
    display: flex;
    align-items: center;
    padding: 1rem ;
}
.contact_info_icon a{
    margin-left: 5px;
}