.article_flex-container{
    display: flex;
    width: calc(50% - 20px);
    padding: 2rem;
}
.article_card-image{
    width: 50%;
    text-align: center;
}
.article_card_text{
   
    display: flex;
    flex-direction: column;
    align-items: left;
    
}
.article_card_text h2{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 20px;
    color: #ffffff;
}
.article_card_text p{
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 300;
    color: #ffffff;
}
@media screen and (max-width:750px){
    .article_flex-container{
        padding: 1.5rem 2rem;
    }
}
@media screen and (max-width:650px){
    .article_flex-container{
        width: 100%;
    }
    .article_card_text h2{
        font-size: 20px ;
    }
    .article_card_text p{
        font-size: 12px;
        
    }
}