.footer_flex-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.nav_links{
    width: 50%;
    display: flex;
    justify-content: space-between;
    /*margin-left: 4rem;*/
}
.nav_links a{
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 300;
}
.footer_copyright{
margin-top: 6rem;
}
.footer_copyright p{
    color: #ffffff;
    font-size: 15px;
}
@media screen and (max-width:850px){
    .nav_links a{
        font-size: 15px;
    }
}
@media screen and (max-width:690px){
    .nav_links{
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .nav_links a{
        line-height: 40px;
    }
    .footer_copyright p{
        font-size: 12px;
    }
}