.project_flex-container{
    display: flex;
    color: #fff;
    flex-direction: column;
    margin-top: 5rem;
}
.project_title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.project_title p{
    font-family: var(--font-family);
    font-size: 15px;
    font-weight: 300;
    color: #ffffff;
}
.project_title h1{
    font-family: var(--font-family);
    font-size: 50px;
    font-weight: 500;
    line-height: 40px;
    color: #ffffff;
}
.project_content{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    margin-top: 2rem;
    gap: 10px;
}
.project_content img{
    width: 100%;
    height: 300px;
    border-radius: 10px;
}

@media screen and (max-width:1050px){
    .project_content{
        grid-template-columns: 50% 50%;
        row-gap: 20px;
    }
}
@media screen and (max-width:850px){
    .project_content{
        grid-template-columns: 100%;
        row-gap: 20px;
    }
}
@media screen and (max-width:550px){
    .project_content img{
        
        height: 200px;
       
    }
}