@import url('https://fonts.googleapis.com/css2?family=Agdasima&family=Bagel+Fat+One&family=Playfair+Display:wght@400;500&family=Poppins&family=Yanone+Kaffeesatz:wght@300&display=swap');
:root{
  --font-family:'Poppins', sans-serif;

  --color-bg: #01020e;
  
  
}

body{
  background: var(--color-bg);
}